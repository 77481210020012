import {Box, Container, Skeleton, Typography} from "@mui/material"
import React, {ReactElement, ReactNode} from "react"
import {Breakpoint} from "@mui/system/createTheme/createBreakpoints"
import {sentenceCase} from "../textUtils"
import {InternalLink} from "../components/links/Links"

interface PageProps {
  title?: string
  subTitle?: string
  maxWidth?: Breakpoint | false
  children: ReactNode
  isLoading?: boolean
  navigationLink?: string
  navigationLinkText?: string
  titleIcon?: ReactNode
}

export function Page({
                       title,
                       subTitle,
                       maxWidth,
                       children,
                       isLoading = false,
                       navigationLink,
                       navigationLinkText,
                       titleIcon
                     }: PageProps): ReactElement {
  return <Box
    sx={{
      position: "relative",
      width: "100%",
      paddingTop: "1rem",
    }}
  >
    {isLoading &&
      <Container
        sx={(theme) => ({
          padding: "1rem 0",
          borderRadius: "1rem",
          [theme.breakpoints.down("sm")]: {
            padding: "1rem",
          },
        })}
        maxWidth={maxWidth || false}
      >
        <Skeleton data-testid={"loading"} variant={"rectangular"}
                  sx={{width: "80%", padding: "0 1rem", margin: "0 1rem"}}/>
      </Container>
    }
    {!isLoading &&
      <Container
        sx={(theme) => ({
          padding: "1rem 0",
          borderRadius: "1rem",
          [theme.breakpoints.down("sm")]: {
            padding: "1rem",
          },
        })}
        maxWidth={maxWidth || false}
      >
        {navigationLink && navigationLinkText &&
          <InternalLink sx={{marginBottom: "1rem", display: "block"}}
                        to={navigationLink}>{navigationLinkText}</InternalLink>
        }
        {title &&
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography variant={"h1"} sx={{marginBottom: subTitle ? 0 : "1rem"}}>
              {sentenceCase(title)}
            </Typography>
            {titleIcon && titleIcon}
          </Box>
        }
        {subTitle &&
          <Typography sx={{marginBottom: "1rem"}}>
            {subTitle}
          </Typography>}
        {children}
      </Container>
    }
  </Box>
}
