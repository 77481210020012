import {RouteObject} from "react-router-dom"

export const remortgagingRoutes: RouteObject[] = [
  {
    path: "remortgaging",
    children: [
      {
        path: "dashboard",
        lazy: () => import("../remortgaging/DashboardLayout"),
        children: [
          {
            index: true,
            lazy: () => import("./RemortgagingHomePage"),
          },
          {
            path: ":customerId",
            lazy: () => import("../remortgaging/CustomerPage"),
          }
        ],
      },
    ],
  },
]
